import { type LinksFunction, type LoaderFunctionArgs, json } from '@remix-run/node';
import {
  Links,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import twStylesHreft from '~/tailwind.css?url';
import { cn } from './lib/utils';
import { NonFlashOfWrongThemeEls, ThemeProvider, useTheme } from './utils/theme-provider';
import { getThemeSession } from './utils/theme.server';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: twStylesHreft }];

export const meta: MetaFunction = () => {
  return [{ title: 'Cometa Admin' }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const themeSession = await getThemeSession(request);

  return json({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
    },
    theme: themeSession.getTheme(),
  });
}

export const queryClient = new QueryClient();

function App() {
  const data = useLoaderData<typeof loader>();

  const [theme] = useTheme();

  return (
    <QueryClientProvider client={queryClient}>
      <html lang="es" className={cn('antialiased dark:bg-gray-950', theme)}>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
          <NonFlashOfWrongThemeEls ssrTheme={Boolean(data.theme)} />
        </head>
        <body className="bg-white dark:bg-gray-950 text-gray-950 dark:text-white">
          <Outlet />
          <ScrollRestoration />
          <script
            // biome-ignore lint/security/noDangerouslySetInnerHtml: default remix setup
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
            }}
          />
          <Scripts />
        </body>
      </html>
    </QueryClientProvider>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <App />
    </ThemeProvider>
  );
}

export default withSentry(AppWithProviders, { wrapWithErrorBoundary: true });

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  // Handle specific HTTP errors
  if (isRouteErrorResponse(error)) {
    return (
      <html lang="es">
        <head>
          <title>Error {error.status}</title>
          <Meta />
          <Links />
        </head>
        <body>
          <div className="min-h-screen flex items-center justify-center">
            <div className="text-center">
              <h1 className="text-3xl font-bold mb-4">Error {error.status}</h1>
              <p className="text-gray-600 dark:text-gray-400 mb-4">{error.data}</p>
              {error.status === 404 ? (
                <p>La página que buscas no existe.</p>
              ) : (
                <p>Ocurrió un error al procesar tu solicitud.</p>
              )}
            </div>
          </div>
          <Scripts />
        </body>
      </html>
    );
  }

  // Handle other errors
  const errorMessage = error instanceof Error ? error.message : 'Error desconocido';

  return (
    <html lang="es">
      <head>
        <title>Error inesperado</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Error inesperado</h1>
            <p className="text-gray-600 dark:text-gray-400 mb-4">{errorMessage}</p>
            {process.env.NODE_ENV === 'development' && error instanceof Error && (
              <pre className="mt-4 text-sm p-4 bg-gray-100 dark:bg-gray-800 rounded overflow-auto text-left max-w-[90vw] md:max-w-[800px] whitespace-pre-wrap break-words">
                <code className="block min-w-full">{error.stack}</code>
              </pre>
            )}
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
